interface IFailedSimulationCardProps {
  isContractDeployment?: boolean;
}

const FailedSimulationCard: React.FC<IFailedSimulationCardProps> = ({
  isContractDeployment,
}) => {
  return (
    <div className="message-wrapper">
      <div className="message-icon">
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0002 11.4001V16.2001M15.0002 21.0001H15.0122M13.3386 5.27013L3.46871 22.3181C2.92127 23.2637 2.64754 23.7365 2.688 24.1245C2.72329 24.463 2.90061 24.7705 3.17584 24.9706C3.49139 25.2001 4.0377 25.2001 5.13033 25.2001H24.8701C25.9627 25.2001 26.509 25.2001 26.8246 24.9706C27.0998 24.7705 27.2771 24.463 27.3124 24.1245C27.3529 23.7365 27.0791 23.2637 26.5317 22.3181L16.6618 5.27012C16.1163 4.32793 15.8436 3.85683 15.4878 3.6986C15.1774 3.56059 14.823 3.56059 14.5126 3.6986C14.1568 3.85683 13.8841 4.32793 13.3386 5.27013Z"
            stroke="#F9BD64"
            strokeWidth="2.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="message-title">Transaction Preview Unavailable</div>
      <div className="message-text">
        {!isContractDeployment ? (
          <>
            Sentinel3 scanned this signature and found no issues. Although we
            don't support its translation, you can proceed with it.
          </>
        ) : (
          <>
            Sentinel3 scanned this signature and found no issues. Although we
            don't support its translation, you can proceed with it.
          </>
        )}
      </div>
    </div>
  );
};

export default FailedSimulationCard;
