/*eslint-disable @typescript-eslint/no-non-null-assertion */
import clsx from 'clsx';
import { useContext } from 'react';
import { SimulationContext } from '../../contexts/SimulationContext';
import {
  RiskSeverityEnum,
  SignatureTypes,
  TrustLevelEnum,
} from '../../helpers/enums/application.enums';
import {
  EyeIcon,
  QuestionIcon,
  StopIcon,
  WarningIcon,
} from '../../helpers/icons/icons';
import {
  ISignatureDescription,
  ISignatureDetail,
} from '../../helpers/interfaces/dataTypes.interface';
import LabeledDescription from '../Shared/LabeledDescription';

interface ISimpleSignatureProps {
  type: SignatureTypes;
}

const SIGNATURE_COMPONENT_DETAILS = new Map<SignatureTypes, ISignatureDetail>([
  [
    SignatureTypes.EIP_4361_PERSONAL_SIGN,
    {
      isSafe: true,
      descriptionList: [
        {
          icon: EyeIcon,
          description:
            'This dApp is requesting a Sign In With Ethereum (SIWE) message signature.',
        },
      ],
    },
  ],
  [
    SignatureTypes.EIP_191_PERSONAL_SIGN,
    {
      isSafe: true,
      descriptionList: [
        {
          icon: EyeIcon,
          description:
            'This dApp is requesting your signature on a personal sign message.',
        },
        {
          icon: StopIcon,
          description:
            'This signature type is often used to prove ownership of a wallet address.',
        },
      ],
    },
  ],
  [
    SignatureTypes.UNKNOWN,
    {
      isSafe: false,
      descriptionList: [
        {
          icon: QuestionIcon,
          description: "We don't recognize this signature.",
        },
        {
          icon: WarningIcon,
          description:
            'Make sure you trust this dApp before signing this message.',
        },
      ],
    },
  ],
  [
    SignatureTypes.ETH_SIG,
    {
      isSafe: false,
      descriptionList: [
        {
          icon: EyeIcon,
          description: 'Legacy insecure signature ',
        },
        {
          icon: StopIcon,
          description:
            'Signing this message is highly insecure and not supported by most wallets.',
        },
      ],
    },
  ],
]);

const SimpleSignature: React.FC<ISimpleSignatureProps> = ({ type }) => {
  const { eipGlobalInformation } = useContext(SimulationContext);

  //If for some reason this errors at runtime due to the non-null assertion, we want it to
  const signatureDetailType = SIGNATURE_COMPONENT_DETAILS.get(type);
  const signatureDetail =
    signatureDetailType ??
    SIGNATURE_COMPONENT_DETAILS.get(SignatureTypes.UNKNOWN)!;

  let warning;
  const displayWarning =
    eipGlobalInformation?.securityRiskData?.globalTrustLevel !==
      TrustLevelEnum.HIGH &&
    eipGlobalInformation?.securityRiskData?.flags?.walletChangesView;

  if (displayWarning) {
    warning = (
      <div className="mt-5">
        <div
          className={clsx(
            'tracking-wide font-semibold text-center font-Manrope',
            (eipGlobalInformation?.securityRiskData.flags?.walletChangesView[0]
              .severity === RiskSeverityEnum.HIGH ||
              type === SignatureTypes.ETH_SIG) &&
              'text-red-600'
          )}
        >
          Warning:{' '}
          {
            eipGlobalInformation?.securityRiskData.flags?.walletChangesView[0]
              .userWarningMessage
          }
        </div>
      </div>
    );
  }

  if (!signatureDetailType) {
    return (
      <div className="message-wrapper">
        <div className="message-icon">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0002 11.4001V16.2001M15.0002 21.0001H15.0122M13.3386 5.27013L3.46871 22.3181C2.92127 23.2637 2.64754 23.7365 2.688 24.1245C2.72329 24.463 2.90061 24.7705 3.17584 24.9706C3.49139 25.2001 4.0377 25.2001 5.13033 25.2001H24.8701C25.9627 25.2001 26.509 25.2001 26.8246 24.9706C27.0998 24.7705 27.2771 24.463 27.3124 24.1245C27.3529 23.7365 27.0791 23.2637 26.5317 22.3181L16.6618 5.27012C16.1163 4.32793 15.8436 3.85683 15.4878 3.6986C15.1774 3.56059 14.823 3.56059 14.5126 3.6986C14.1568 3.85683 13.8841 4.32793 13.3386 5.27013Z"
              stroke="#F9BD64"
              strokeWidth="2.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="message-title">Transaction Preview Unavailable</div>
        <div className="message-text">
          <>
            Sentinel3 scanned this signature and found no issues. Although we
            don't support its translation, you can proceed with it.
          </>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        className={clsx(
          'p-3',
          (eipGlobalInformation?.securityRiskData.flags?.walletChangesView[0]
            .severity === RiskSeverityEnum.HIGH ||
            type === SignatureTypes.ETH_SIG) &&
            'text-red-600'
        )}
      >
        {signatureDetail.descriptionList.map(
          (signatureType: ISignatureDescription, index: number) => {
            return (
              <LabeledDescription
                key={index}
                icon={signatureType.icon}
                description={signatureType.description}
              />
            );
          }
        )}
        {displayWarning && warning}
      </div>
    </div>
  );
};

export default SimpleSignature;
